import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Box } from '@mui/material';

import { DateTime, SearchStringMulti, SelectMulti, SearchString, DownloadButton } from "features/pages/components";
import { Loader, Button } from "UI";

import {
    selectFilters,
    updateFilters,
    selectLoading,
} from "features/pages/pagesSlice";

import {
    sendGetContent,
    selectLoading as selectLoadingContent,
} from "features/content/contentSlice";

const FiltersComponent = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const { filters: loading } = useSelector(selectLoading);
    const { content: loadingContent } = useSelector(selectLoadingContent);

    const handleChangeFilter = useCallback((props) => {
        dispatch(updateFilters(props));
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(sendGetContent({ page: 0 }));
    }

    return <Loader loading={loading} variant="replace">
        <Stack spacing={2} sx={{ p: 2 }}>
            {Object.keys(filters).map(filterKey => {

                const filter = filters[filterKey];

                switch (filter.type) {

                    case "DateTime": // returntype: "Int64"
                        return <DateTime
                            key={filterKey}
                            name={filterKey}
                            {...filter}
                            onChange={handleChangeFilter}
                        />;


                    case "SearchStringMulti": // returntype: "StringDictionary"
                        return <SearchStringMulti
                            key={filterKey}
                            name={filterKey}
                            {...filter}
                            onChange={handleChangeFilter}
                        />;


                    case "SelectMulti": // returntype: "StringArray"
                        return <SelectMulti
                            key={filterKey}
                            name={filterKey}
                            {...filter}
                            onChange={handleChangeFilter}
                        />;


                    case "SearchString": // returntype: "StringDictionary"
                        return <SearchString
                            key={filterKey}
                            name={filterKey}
                            {...filter}
                            onChange={handleChangeFilter}
                        />;


                    default:
                        return null;
                }


            })}

            <Box sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "#FFF",
                zIndex: 1,
            }}>
                <Stack direction="row" spacing={1}>
                    <DownloadButton />
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit}
                        loading={loadingContent}
                        sx={{ margin: 0, mt: "25px !important" }}
                    >Сформировать отчёт</Button>
                </Stack>
            </Box>
        </Stack>
    </Loader>
}

export const Filters = memo(FiltersComponent);
