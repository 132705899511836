import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    Menu,
    MenuItem,
} from '@mui/material';



import { styled, alpha } from '@mui/material/styles';

import ArchiveIcon from '@mui/icons-material/Archive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import { Loader } from "UI";

import {
    selectFilters,
    selectTabs,
    selectLoading,
} from "features/pages/pagesSlice";

import {
    selectSelectedTab,
    sendGetFile,
} from "features/content/contentSlice";

import {
    sendGetContent,
    selectLoading as selectLoadingContent,
} from "features/content/contentSlice";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const DownloadButtonComponent = () => {
    const dispatch = useDispatch();
    const tabs = useSelector(selectTabs);
    const selectedTab = useSelector(selectSelectedTab);
    const { content: loadingContent } = useSelector(selectLoadingContent);

    // console.log(tabs);
    // console.log(selectedTab);


    const tab = tabs[selectedTab] || null;


    let fileDownload = tab?.fileDownload || []

    // fileDownload = fileDownload[0] ? [fileDownload[0]] : [];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickDownload = (path) => {
        // console.log(path);
        dispatch(sendGetContent({ path }));
        // dispatch(sendGetFile({url}));
    };

    if (!fileDownload.length) {
        return null;
    }


    if (fileDownload.length === 1) {
        return <>
            <Button
                title={fileDownload[0].name}
                id="button"
                variant="outlined"
                disableElevation
                onClick={() => handleClickDownload(fileDownload[0].url)}
                sx={{ mt: "25px !important", minWidth: "30px", width: "34px" }}
                // color="info"
            >
                <SaveAltIcon sx={{ m: "0px !important" }} />
            </Button>
        </>;
    }



    return <>
        <Button
            id="button"
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="outlined"
            disabled={loadingContent}
            disableElevation
            onClick={handleClick}
            sx={{ mt: "25px !important", minWidth: "30px", width: "34px" }}
            // color="info"
        >
            <SaveAltIcon sx={{ m: "0px !important" }} />
        </Button>
        <StyledMenu
            id="menu"
            MenuListProps={{
                'aria-labelledby': 'button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {fileDownload.map((item, key) => <MenuItem
                key={key}
                onClick={() => handleClickDownload(item.url)}
                disableRipple
            >
                <ArchiveIcon />
                {item.name}
            </MenuItem>)}

        </StyledMenu>
    </>;
}

export const DownloadButton = memo(DownloadButtonComponent);
