const options = {
  fill: {
    type: "gradient",

    gradient: {
      shade: 'light',
    },
  },

  chart: {
    type: 'bar',
    width: "100%",
    stacked: true,
    height: 500,
    stackType: '100%', // 100% normal
  },
  grid: {
    show: true,
    borderColor: '#90A4AE',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    },
    row: {
      colors: ['#BDCDD6', '#EEE9DA'],
      opacity: 0.1
    },
    column: {
      colors: ['#BDCDD6', '#EEE9DA'],
      opacity: 0.1
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
  },

  legend: {
    show: true,
    labels: {
      useSeriesColors: false,
    },
  },
  plotOptions: {
    bar: {
      // columnWidth: '70%',
      barHeight: '100%',
      horizontal: true,
      dataLabels: {
        total: {
          enabled: false,
          offsetX: 6,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    },
  },
  // colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7'],
  dataLabels: {
    enabled: true,
    textAnchor: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      colors: ['#FFF'],
      fontSize: "14"
    },
    formatter: function (val, opt) {
      const value = opt.config.series[opt.seriesIndex].data[opt.dataPointIndex];

      return `${Number(value.toFixed(2))}`; //  (${Number(val.toFixed())}%)
    },
    dropShadow: {
      offsetX: 0,
      enabled: true,
      top: 0,
      left: 0,
      blur: 2,
      color: '#000',
      opacity: 0.6
    },
    // background: {
    //   enabled: true,
    //   foreColor: '#123d83',
    //   padding: 3,
    //   borderRadius: 2,
    //   borderWidth: 1,
    //   borderColor: '#123d83',
    //   opacity: 0.5,
    //   dropShadow: {
    //     enabled: false,
    //     top: 10,
    //     left: 1,
    //     blur: 1,
    //     color: '#000',
    //     opacity: 0.45
    //   }
    // },
  },
  stroke: {
    width: 1,
    colors: ['#fff']
  },
  xaxis: {
    labels: {
      show: true
    },
    axisBorder: {
      show: false,
      color: '#78909C',
      height: 0,
      width: '100%',
      offsetX: 0,
      offsetY: 0
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#78909C',
      height: 0,
      offsetX: 0,
      offsetY: 0
    },
  },
  yaxis: {
    labels: {
      align: 'right',
      show: true,
      maxWidth: 380,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-yaxis-label',
      },
    }
  },
  title: {
    align: 'center',
    floating: true
  },

  tooltip: {
    theme: 'dark',
    intersect: true,
    fillSeriesColor: false,
  },

};

export default options;