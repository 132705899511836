
import { APP_SERVICE } from "./constants";
import { POST } from "helpers";


export function fetchAuth(path, data) {
  console.log("fetchAuth", path, data);
  return POST({
    path,
    data: {
      service: APP_SERVICE,
      ...data,
    },
  });
}
