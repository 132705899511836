import { API_URLS, APP_SERVICE } from "constants";
import { POST } from "helpers";

export function fetchGetContent({ key, authkey, path, filters, tab, page, limit }) {
  return POST({
    path: `${API_URLS.root}/${path}`,
    data: {
      key,
      authkey,
      service: APP_SERVICE,
      page: page + 1,
      // groupDate: "Hour", // Hour,Day,Week,Month
      limit,
      filters,
      tab,
    },
  });
}
