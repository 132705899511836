import  { API_URLS as EX_API_URLS } from "./APIUrls";
export const API_URLS = EX_API_URLS; // fix локальной проблемы сборки, в дальнейшем можно экспортировать нормально 


export const APP_SERVICE = "43";
export const DRAWER_WIDTH = 340;

export const API_KEYS = {
    service: "7e649ecb733d2af1",
    passReset: "9152ea8756f8f2b3",
    passRequest: "c47ee7e666d6be7e",
    checkReg: "340f725c9ccb9779",
};

export const WARNINGS_ICONS = {
    240014: "", // - Конфликты
    170014: "disease", // - Противопоказания по нозологиям
    320014: "unidirfd", // - Антагонизм на уровне фармакологической группы

    260014: "unidirfg", // - Общая фармакологическая группа
    340014: "unidiratx", // - Общая химическая группа
    300014: "mutation", // - Не совместимость на уровне генома

    270014: "onedv", // - Дублирующее действующее вещество
    280014: "bidirfd", // - Разнонаправленное действие
    250014: "semigroup", // - Однонаправленное действие
    310014: "clinrec", // - Не входит в состав клинических рекомендаций
    330014: "seffect", // - Побочные эффекты
    30014: "pregant", // - Применение при беременности и кормлении грудью
    230014: "polypragmasy", // - Полипрагмазия
    290014: "dose", // - Нарушение режима дозирования
    140014: "noage", // - Ограничения по возрасту // noage
    20014: "alcohol", // - Совместимость с алкоголем
    220014: "allergy", // - Аллергия
    210014: "interdrug", // - Межлекарственные взаимодействия
    200014: "driving", // - Сочетание с операторской деятельностью
};

export const TABLE_ROW_STATUSES = {
    "Active": {
        color: "#36dcb5",
        title: "Активно",
    },
    "Unactive": {
        color: "#d96a82",
        title: "Неактивно",
    },
    "Default": {
        color: "inherit",
        title: ""
    }
};

// COLORS[index % COLORS.length

export const COLORS = [
    "#d7263d",
    "#2364aa",
    "#c5d86d",
    "#f46036",
    "#2e294e",
    "#fac748",
    "#f88dad",
    "#1b998b",
    "#c5d86d",
    "#1d2f6f",
    "#8390fa",
    "#3da5d9",
    "#73bfb8",
    "#fec601",
    "#ea7317",
];


// export const COLORS = [
//     "#fd7f6f",
//     "#7eb0d5",
//     "#b2e061",
//     "#bd7ebe",
//     "#ffb55a",
//     "#ffee65",
//     "#beb9db",
//     "#fdcce5",
//     "#8bd3c7",
//     "#ea5545",
//     "#f46a9b",
//     "#ef9b20",
//     "#edbf33",
//     "#ede15b",
//     "#bdcf32",
//     "#87bc45",
//     "#27aeef",
//     "#b33dc6",
// ];