import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import {
    sendGetPages,
    selectPages,
    selectCurrentPage,
    setCurrentPage,
} from "features/pages/pagesSlice";
import { setSelectedTab } from "features/content/contentSlice";

export const Menu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pages = useSelector(selectPages);

    const currentPage = useSelector(selectCurrentPage);

    useEffect(() => {
        dispatch(sendGetPages());
    }, [dispatch]);

    useEffect(() => {
        if (currentPage) {
            dispatch(setSelectedTab(false));

            /**
             * @todo Возможно, сюда же нужно добавить очистку данных других табов, чтобы освободить хранилище
            */

            navigate(`/pages/${currentPage}`, { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, dispatch]);

    const handleClickMenuItem = (event) => {
        dispatch(setCurrentPage(event.target.name));
    }

    return <Box
        variant="dense"
        sx={{
            flexGrow: 1,
            display: { md: 'flex' }
        }}
    >
        {Object.keys(pages).map((pageId) => (
            <Button
                variant={"text"}
                key={pageId}
                name={pageId}
                onClick={handleClickMenuItem}
                sx={{
                    my: 0,
                    color: 'white',
                    display: 'block',
                    margin: "0 8px",
                    outline: currentPage === pageId ? "0px solid #FFF" : "",
                    background: currentPage === pageId ? "rgba(255, 255, 255, 0.2)" : "",
                }}
            >
                {pages[pageId].text}
            </Button>
        ))}
    </Box>
}
