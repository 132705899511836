import React from 'react';
import { BrowserRouter } from "react-router-dom";

import { Restricted } from './features/auth';
import { Main } from './features/main/Main';

import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Restricted>
            <Main />
          </Restricted>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
