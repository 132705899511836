import ru from "apexcharts/src/locales/ru";
// import { COLORS } from "constants";

const getDaysArray = (start, end, data) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {

        // приводим все даты ко времени 00:00
        dt.setUTCHours(0, 0, 0, 0);
        const currDate = dt.getTime();

        var value = data.find(monthlyLog => monthlyLog[0] === currDate) || 0;
        if (value) {
            value = value[1];
        }
        arr.push([currDate, value]);
    }
    return arr;
}

const removeEmpty = (series) => {
    let data = [];

    series.forEach(item => {
        data.push({
            ...item,
            data: item.data.filter(d => d[1])
        });
    });
    return data;
}

const setChartType = async (chart, type, addOptions) => {
    let { chart: chartOptions = {}, ...opts } = addOptions;

    await chart.updateOptions({
        ...opts,
        chart: {
            ...chartOptions,
            animations: {
                enabled: false
            }
        }
    });

    await chart.updateOptions({
        chart: {
            type: type
        },
    });

    await chart.updateOptions({
        chart: {
            animations: {
                enabled: true
            }
        }
    });
}

const setChartToPoints = (chart, options, e) => {
    const series = options.config.series;
    let start = 9999999999999;
    let end = 0;
    let data = [];

    series.forEach((item, i) => {
        start = Math.min(start, item.data[0][0]);
        end = Math.max(end, item.data[item.data.length - 1][0]);
    });

    series.forEach((item, i) => {
        data.push({
            ...item,
            data: getDaysArray(start, end, item.data)
        });
    });

    const addOptions = {
        stroke: {
            width: 2,
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            shared: true,
            intersect: false
        }
    };

    setChartType(chart, "area", addOptions);
    chart.updateSeries(data);
};

const setChartToBars = async (chart, options, e) => {
    const addOptions = {
        stroke: {
            width: 1,
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: true
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    };

    const series = await removeEmpty(options.config.series);
    await chart.updateSeries(series);
    await setChartType(chart, "bar", addOptions);
}

const setChartToArea = async (chart, options, e) => {
    const addOptions = {
        stroke: {
            width: 0,
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    };

    const series = await removeEmpty(options.config.series);
    await chart.updateSeries(series);
    await setChartType(chart, "area", addOptions);

}

const setChartToHeatmap = async (chart, options, e) => {
    if (options.config.chart.type === "heatmap") {
        return false;
    }
 
    const addOptions = {
        stroke: {
            width: 0,
        },
        markers: {
            size: 0,
        },
        dataLabels: {
            enabled: true
        },
        // chart: {
        //     zoom: {
        //         enabled: false, // ломает
        //     },
        //     toolbar: {
        //         tools: {
        //             zoom: false,
        //             zoomin: false,
        //             zoomout: false,
        //         },
        //     }
        // },
    };

    const series = await removeEmpty(options.config.series);
    await chart.updateSeries(series);
    setChartType(chart, "heatmap", addOptions);
}

const options = {
    stroke: {
        curve: 'smooth',
        width: 0,
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left'
    },
    // colors: COLORS,


    chart: {
        stacked: false,
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            autoSelected: "zoom",
            tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
                customIcons: [
                    {
                        icon: '<img src="/icons/line-graph.png" width="18" id="cb1">',
                        index: 6,
                        title: 'Линейный график',
                        class: 'custom-icon custom-icon-first',
                        click: setChartToArea
                    },
                    {
                        icon: '<img src="/icons/bar-chart.png" width="18">',
                        index: 7,
                        title: 'Гистограмма',
                        class: 'custom-icon',
                        click: setChartToBars
                    },
                    {
                        icon: '<img src="/icons/bar-graph.png" width="18">',
                        index: 8,
                        title: 'Точный линейный график',
                        class: 'custom-icon',
                        click: setChartToPoints
                    },
                    {
                        icon: '<img src="/icons/heatmap.png" width="18">',
                        index: 9,
                        title: 'Тепловая карта',
                        class: 'custom-icon',
                        click: setChartToHeatmap
                    },
                ]
            },
        },
        locales: [ru],
        defaultLocale: "ru",
        type: 'area',
        height: 350,
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
        },
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 4,
        hover: {
            size: 6,
            sizeOffset: 10
        }
    },
    title: {
        align: 'left'
    },
    // fill: {
    //     type: 'gradient',
    //     gradient: {
    //         shadeIntensity: 1,
    //         inverseColors: false,
    //         opacityFrom: 0.7,
    //         opacityTo: 0.1,
    //         stops: [0, 90, 100]
    //     },
    // },
    plotOptions: {
        bar: {
            borderRadius: 1,
            horizontal: false,
            dataLabels: {
                position: 'top',
            },
            columnWidth: '100%',
            barHeight: '100%',
        }
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return val;
            },
        },
        min: 0,
        max: function (max) { return max + 2 },
        forceNiceScale: true,
        decimalsInFloat: 2,
        // tickAmount: 6,

    },
    xaxis: {
        type: 'datetime',
    },
    tooltip: {
        shared: false,
        intersect: true
    }
};

export default options;