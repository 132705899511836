import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';

import { checkErrors } from 'helpers';

import { Button } from "../Button";
import { TextField } from "../TextField";

import {
  selectAuthData,
  selectLoading,
  selectErrors,
  setErrors,
  updateAuthData,
  sendRecovery,
} from '../../authSlice';


export function ResetPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const authData = useSelector(selectAuthData);
  const errors = useSelector(selectErrors);

  const handleSubmit = async () => {
    const formErrors = checkErrors(authData, [
      { name: "email", type: "email", required: true },
    ]);

    dispatch(setErrors(formErrors));

    if (!Object.keys(formErrors).length) {
      const request = await dispatch(sendRecovery());

      if (request.meta.requestStatus !== "rejected") {
        navigate("/login");
      }
    }
  };

  const handleChangeField = (event) => {
    const name = event.target.id;
    let value = event.target.value;

    dispatch(updateAuthData({
      [name]: value.trim(),
    }));
  };

  useEffect(() => {
    dispatch(setErrors({}));
  }, [dispatch]);

  return (
    <Stack
      direction="column"
      spacing={2}
    >
      <TextField
        error={!!errors.email}
        id="email"
        label="Email"
        helperText={errors.email}
        value={authData.email}
        onChange={handleChangeField}
      />

      <Button
        onClick={handleSubmit}
        loading={loading}
        title="Отправить код восстановления"
      />
    </Stack>
  );
}
