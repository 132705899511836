import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchTextByCode({ key, authkey, code }) {

  const requestData = {
    "active_language": "ru",
    codes: [code],
  };

  return POST({
    path: API_URLS.main.TextByCode,
    data: requestData,
  });
}

