import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetPages, fetchSearch } from "./pagesAPI";

const initialState = {
  list: {},
  currentPage: "",
  searchResult: {},
  searchLoading: {},
  sharedFilters: {
    // mnnIds: {
    //   value: [
    //     {
    //       ulongId: 35770052,
    //       name: "Варфарин натрия",
    //       level: ""
    //     },
    //     {
    //       ulongId: 13160052,
    //       name: "Пропранолол",
    //       level: ""
    //     },
    //   ]
    // },
    // drugIds: {
    //   value: [{
    //     ulongId: 27520062,
    //     name: "Конкор натрия",
    //     level: ""
    //   }]
    // }
    // drugIds: {
    //   value: [{
    //     id: 53200062,
    //     name: "Глюкоза",
    //     level: ""
    //   }]
    // }
  },
  loading: {
    pages: false,
    filters: true,
    search: false,
  }
};

export const getSearchType = (advancedOptions = []) => {
  const searchType = advancedOptions[0]?.value || "global";

  return searchType;
}

export const sendSearch = createAsyncThunk(
  "pages/fetchSendSearch",
  async (props, { getState }) => {
    const { text, advancedOptions = [] } = props;
    const { auth } = getState();

    const response = fetchSearch({ text, key: auth.key, authkey: auth.authkey, advancedOptions });

    return response;
  }
);

export const sendGetPages = createAsyncThunk(
  "pages/fetchSendGetPages",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetPages({ key: auth.key, authkey: auth.authkey });
    return response;
  }
);

export const authSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateSharedFilters: (state, action) => {
      state.sharedFilters = {
        ...action.payload,
        ...state.sharedFilters,
      }
    },
    updateFilters: (state, action) => {
      const filterKey = action.payload.name;
      let filterObject;

      if (state.sharedFilters.hasOwnProperty(filterKey)) {
        filterObject = state.sharedFilters[filterKey];
      } else {
        filterObject = state.list[state.currentPage].filters[filterKey];
      }

      if (action.payload.value) {
        filterObject.value = action.payload.value;
      }
      if (action.payload.realValue) {
        filterObject.realValue = action.payload.realValue;
      }
    },

    clearSearchResult: (state, action) => {
      state.searchResult[action.payload] = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendGetPages.pending, (state) => {
        state.status = "loading";
        state.loading.pages = true;
      })
      .addCase(sendGetPages.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.pages = false;

        const pages = action.payload.pages;
        state.list = pages;

        // set first page as selected
        const allPagesKeys = Object.keys(state.list);
        if (allPagesKeys.length) {
          state.currentPage = allPagesKeys[0];
        }

        // sharedFilters
        state.loading.filters = false;

        let sharedFilters = state.sharedFilters;

        Object.keys(pages).forEach(page => {
          const filters = pages[page].filters;

          /**
           * @shitfix чтобы фильтровать тестовые данные
           */
          // filters.from = {
          //   ...filters.from,
          //   value: 1608999398000
          // };
          // filters.to = {
          //   ...filters.to,
          //   value: 1665850597000
          // };

          Object.keys(filters).forEach(filterKey => {
            if (filters[filterKey].shared && !sharedFilters.hasOwnProperty(filterKey)) {
              sharedFilters[filterKey] = { ...filters[filterKey] };
            }
          });
        });

      })
      .addCase(sendGetPages.rejected, (state, action) => {
        state.status = "idle";
        state.loading.pages = false;
      })

      .addCase(sendSearch.pending, (state, action) => {
        state.status = "loading";
        const searchType = getSearchType(action.meta?.arg?.advancedOptions);
        state.searchLoading[searchType] = true;

      })
      .addCase(sendSearch.fulfilled, (state, action) => {
        state.status = "idle";

        if (action.payload.concepts) {
          const searchType = getSearchType(action.meta?.arg?.advancedOptions);
          state.searchResult[searchType] = action.payload.concepts.map(item => ({
            ...item,
            id: item.ulongId // @shitfix @todo убрать когда с бэка придёт id
          }));
          state.searchLoading[searchType] = false;
        }
      })
      .addCase(sendSearch.rejected, (state, action) => {
        state.status = "idle";
        const searchType = getSearchType(action.meta?.arg?.advancedOptions);
        state.searchLoading[searchType] = false;
      });
  },
});

export const {
  setCurrentPage,
  updateFilters,
  clearSearchResult,
  updateSharedFilters,
} = authSlice.actions;

export const selectPages = (state) => state.pages.list;
export const selectLoading = (state) => state.pages.loading;
export const selectSearchResult = (state) => state.pages.searchResult;
export const selectCurrentPage = (state) => state.pages.currentPage;

export const selectFilters = (state) => {
  if (state.pages.currentPage && state.pages.list[state.pages.currentPage].filters) {

    let filters = state.pages.list[state.pages.currentPage].filters;
    let sharedFilters = state.pages.sharedFilters;
    let proxedFilters = {};

    Object.keys(filters).forEach(filterKey => {
      if (sharedFilters.hasOwnProperty(filterKey)) {
        proxedFilters[filterKey] = { ...sharedFilters[filterKey] };
      } else {
        proxedFilters[filterKey] = { ...filters[filterKey] };
      }
    });

    return proxedFilters;
  }
  return {};
};

export const selectTabs = (state) => {
  if (state.pages.currentPage && state.pages.list[state.pages.currentPage].tabs && state.pages.list[state.pages.currentPage].tabs.length) {
    return state.pages.list[state.pages.currentPage].tabs;
  }
  return [];
};

export default authSlice.reducer;
