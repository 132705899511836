import { SERVER } from './server';

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9046",
    },
    dev: {
        api: "https://service.socmedica.dev:9046",
    },
    box: {
        api: "http://ecp.box:30946",
    },
};


function getUrls() {
    const { api: APIServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        main: {
            GetPages: APIServer + "/Pages/GetPages",
            Search: APIServer + "/Search/ConceptList",
            TextByCode: APIServer + "/Info/TextByCode",
        },
    }
}

export const API_URLS = getUrls();