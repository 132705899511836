import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Alert, ToggleButtonGroup, ToggleButton } from '@mui/material';

import { selectLoading } from "features/content/contentSlice";

import { Loader } from "UI";

import { Table, LineChart, BarChart, PieChart, StackedChart, StackedChart100 } from "features/content";
import { selectContentType, selectConfigByKey, updateConfig } from "features/content/contentSlice";

const ContentMemedComponent = () => {
    const dispatch = useDispatch();
    const passibleTabs = ["table", "lineChart", "pieChart", "barChart"];
    const type = useSelector(selectContentType);
    const conflictsViewType = useSelector((state) => selectConfigByKey(state, "conflictsViewType"));
    const { content: loading } = useSelector(selectLoading);

    let dataType = type;
    let ContentComponent = null;
    let MenuComponent = null;

    if (!passibleTabs.includes(dataType)) {
        return <Alert severity="info">Загрузка данных...</Alert>;
    }

    const handleChangeViewType = (_, newValue) => {
        dispatch(updateConfig({ conflictsViewType: newValue }));
    }

    switch (dataType) {
        case "table":
            ContentComponent = Table;
            break;

        case "lineChart":
            ContentComponent = LineChart;
            break;

        case "barChart":
            ContentComponent = BarChart;
            break;

        case "pieChart":

            switch (conflictsViewType) {
                case "pie":
                    ContentComponent = PieChart;
                    break;

                case "stacked":
                    ContentComponent = StackedChart;
                    break;

                case "stacked100":
                    ContentComponent = StackedChart100;
                    break;

                default:
                    ContentComponent = StackedChart;
                    break;
            }

            MenuComponent = <ToggleButtonGroup
                color="primary"
                value={conflictsViewType}
                exclusive
                onChange={handleChangeViewType}
                aria-label="Platform"
            >
                <ToggleButton value="stacked100">Stacked chart 100%</ToggleButton>
                <ToggleButton value="stacked">Stacked chart</ToggleButton>
                <ToggleButton value="pie">Pie chart</ToggleButton>
            </ToggleButtonGroup>;

            break;

        default:
            ContentComponent = <Loader loading={loading} ><Alert severity="info">Укажите фильтры в левой колонке и нажмите "Сформировать отчёт".</Alert></Loader>;
            break;
    }

    return <Box sx={{ height: "calc(100vh - 142px)", width: '100%' }}>
        <Loader loading={loading} variant="replace">
            <>
                {MenuComponent}
                <ContentComponent />
            </>
        </Loader>
    </Box>;
}

export const Content = memo(ContentMemedComponent);
