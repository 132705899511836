import React from 'react';

import { TextField as MUTextField } from '@mui/material';

export function TextField(props) {
  const { loading, onClick, title, ...MUProps } = props;

  return (
    <MUTextField
      variant="outlined"
      size="small"
      // InputLabelProps={{ shrink: true }}
      {...MUProps}
      value={MUProps.value || undefined}
    />
  );
}