import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DRAWER_WIDTH } from "constants";
import { appStorage } from "helpers";

import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, CssBaseline, AppBar as MuiAppBar, Toolbar, Typography, Divider, IconButton } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import { Menu, Filters, Tabs } from "features/pages";
import { Alerts } from "features/alerts";
import { AboutDialog } from "UI/AboutDialog";

import { selectDrawerOpen, setDrawerOpen } from "features/main/mainSlice";

const drawerWidth = DRAWER_WIDTH;

const ContentHolder = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export const Main = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const open = useSelector(selectDrawerOpen);

    const handleDrawerOpen = () => dispatch(setDrawerOpen(true));
    const handleDrawerClose = () => dispatch(setDrawerOpen(false));
    const handleLogout = () => {
        appStorage.removeItem("authkey");
        appStorage.removeItem("key");
        window.location = "/";
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                sx={{ background: "linear-gradient(90deg, rgb(23, 46, 66) 8%, rgb(27, 140, 206) 22%)" }}
            >
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ marginRight: 4 }}
                    >
                        Статистика
                    </Typography>

                    <Menu />

                    <AboutDialog boxProps={{
                        style: { margin: "0 8px" }
                    }}>
                        <IconButton
                            color="inherit"
                            aria-label="about"
                            edge="end"
                        >
                            <InfoIcon />
                        </IconButton>
                    </AboutDialog>

                    <IconButton
                        color="inherit"
                        aria-label="logout"
                        onClick={handleLogout}
                        edge="end"
                    >
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <Filters />

            </Drawer>
            <ContentHolder open={open}>
                <DrawerHeader />
                <Tabs />
            </ContentHolder>
            <Alerts />
        </Box>
    );
}
