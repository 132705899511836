import React from "react";

import { MenuItem, FormControl, Select } from '@mui/material';

export const GroupSelector = ({ onChange, value, items }) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, ml: 2 }}>
      <Select
        value={value}
        onChange={onChange}
      >
        {items.map((item, i) => <MenuItem value={i} key={i}>{item}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
