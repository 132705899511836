import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTextByCode } from "./mainAPI";


const initialState = {
  drawerOpen: true,
  aboutText: "",
  aboutLoading: false,
  loading: {
    about: false,
  }
};


export const sendTextByCode = createAsyncThunk(
  "main/fetchSendTextByCode",
  async (code, { getState }) => {
    const { auth } = getState();
    const response = fetchTextByCode({ code, key: auth.key, authkey: auth.authkey });

    return response;
  }
);


export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendTextByCode.pending, (state, action) => {
        state.loading.about = true;
      })
      .addCase(sendTextByCode.fulfilled, (state, action) => {
        const data = action.payload || {};

        if (data && data.texts && data.texts["system_information_statistic"]) {
          state.aboutText = data.texts["system_information_statistic"];
        }

        state.loading.about = false;
      })
      .addCase(sendTextByCode.rejected, (state, action) => {
        state.loading.about = false;
      });
  },


});

export const {
  setDrawerOpen,
} = mainSlice.actions;

export const selectDrawerOpen = (state) => state.main.drawerOpen;
export const selectAboutText = (state) => state.main.aboutText;

export default mainSlice.reducer;
