import React, { useState } from "react";

import { TableCell as MUITableCell, Box, Popover } from "@mui/material";

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { DrugsList } from "features/content/";
import styles from '../../DataGrid.module.css';

export const TableCell = ({ children, header, noMinWidth, component = "td", cellDataAdditional = null, empty = false, ...otherProps }) => {
    const [popoverContent, setPopoverContent] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopper = Boolean(anchorEl);

    const handleShowPopper = (event, cellData) => {
        if (Array.isArray(cellData) && cellData.length > 1) {
            handlePopoverOpen(event);
            setPopoverContent(<DrugsList cellData={cellData} cellDataAdditional={cellDataAdditional} />);
        }
    }

    const cellParse = (cellData) => {
        let parsedCell = "";
        let hasIcon = false;

        if (Array.isArray(cellData)) {
            if (cellData.length > 1) {
                parsedCell = cellData.join(", ");
                hasIcon = true;
            } else {
                parsedCell = cellData[0];
            }
        } else {
            parsedCell = cellData;
        }

        if (hasIcon) {
            parsedCell = <Box sx={{
                display: "flex",
            }}>
                <FormatListBulletedIcon
                    color="primary"
                    fontSize="small"
                    sx={{ mr: 1 }}
                />
                <Box
                    sx={{
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >{parsedCell}</Box>
            </Box>;
        }

        return parsedCell || (empty ? "" : "-");
    }

    return <MUITableCell
        scope="row"
        variant={header ? "head" : "body"}
        component={component}
        className={noMinWidth ? styles.tableCellImg : styles.tableCell}
        onMouseEnter={(event) => handleShowPopper(event, children)}
        onMouseLeave={handlePopoverClose}
        {...otherProps}
    >
        {cellParse(children)}
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={openPopper}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ p: 1 }}>{popoverContent}</Box>
        </Popover>
    </MUITableCell>;
}
