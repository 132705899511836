import React from 'react';

import { Button as MUButton } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoopIcon from '@mui/icons-material/Loop';

import styles from './Button.module.css';

export function Button(props) {
  const { loading, onClick, children, ...MUProps } = props;

  const handlerClick = () => {
    onClick && onClick();
  }

  return (
    <MUButton
      variant="contained"
      onClick={handlerClick}
      disabled={loading}
      endIcon={!loading ? <ArrowCircleRightIcon /> : <LoopIcon className={styles.loadingIcon} />}
      className={styles.button}
      fullWidth
      {...MUProps}
    >
      {children}
    </MUButton>
  );
}
