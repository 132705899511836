import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-apexcharts";

import { getGroupColor } from "helpers";

import {
    selectContent,
    selectLoading,
    selectWarningOpen,
    setWarningOpen,
    sendGetContent,
} from "features/content/contentSlice";
import { Table } from "features/content";

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Alert, Dialog, Slide } from '@mui/material';

// import content from "./dataMock";
import options from "./options";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChartComponent = ({ name, data, labels, onDataPointSelection, counts, type, colors }) => {
    const series = data.map(dataItem => ({
        ...dataItem,
        name: `${dataItem.name} (${dataItem.data.reduce((a, b) => a + b, 0)})`
    }));

    return <div style={{ width: "100%" }}><Chart options={{
        ...options,
        fill: {
            ...options.fill,
            colors
        },
        legend: {
            ...options.legend,
            labels: {
                ...options.legend.labels,
                colors,
            },
            markers: {
                fillColors: colors,
            }
        },
        title: {
            ...options.title,
            text: name,
        },
        xaxis: {
            ...options.xaxis,
            categories: labels.map((label, index) => `${label} (${counts[index]})`),
            labels: {
                formatter: (val) => ""
            }
        },
        plotOptions: {
            ...options.plotOptions,
            bar: {
                ...options.plotOptions.bar,
                barHeight: type === "100%" ? "100%" : "70%",
            },
        },
        chart: {
            ...options.chart,
            stackType: type === "100%" ? "100%" : "normal",
            events: {
                dataPointSelection: (event, chartContext, config) => onDataPointSelection(config.dataPointIndex, config.seriesIndex),
            },
        },
    }}
        type='bar'
        series={series}
        width={"100%"}
        height={"250"}
    /></div>;
}

export const StackedChart = ({ type }) => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector(selectWarningOpen);
    const { content: loading } = useSelector(selectLoading);
    const content = useSelector(selectContent);

    let chart = useMemo(() => [...content.chart || []], [content.chart]);

    const conflictTypes = useMemo(() => {
        let types = [...Array.isArray(content.conflictTypes) ? content.conflictTypes : []];

        types.sort((a, b) => a.value - b.value);

        return types.map(type => ({
            ...type,
            color: getGroupColor(type.value)
        }));
    }, [content.conflictTypes]);

    const getData = () => {
        const data = conflictTypes.map(item => ({
            name: item.name,
            data: chart.map(chartItem => chartItem.data[item.id] || 0),
        }));

        return {
            name: "Взаимодействия",
            data,
            colors: conflictTypes.map(type => type.color),
            labels: chart.map(v => v.name) || [],
            counts: chart.map(v => v.count) || [],
        };
    };

    const handleOpenDrawer = useCallback((selectedPiece, point) => {
        const id = chart?.[selectedPiece]?.id || null;
        const conflict = conflictTypes[point];

        dispatch(setWarningOpen(true));
        dispatch(sendGetContent({
            isWarnings: true,
            forceFilters: {
                warningTexts: [+id],
                valueA: +conflict.value || null,
            }
        }));

    }, [chart, conflictTypes, dispatch]);

    const handleCloseDrawer = useCallback((e) => {
        dispatch(setWarningOpen(false));
    }, [dispatch]);

    if ((!content.chart?.length && !loading) || !Array.isArray(content?.chart)) {
        return <Alert severity="info">Нет данных. Попробуйте изменить фильтры.</Alert>;
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"xl"}
                TransitionComponent={Transition}
                open={drawerOpen}
                onClose={handleCloseDrawer}
            >
                <Table isWarningTable fullSize />
            </Dialog>

            <Grid container sx={{ width: "99%" }}>
                <Grid
                    key={`group_1`}
                    md={12}
                    lg={12}
                    spacing={100}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <ChartComponent
                        key={`group_1`}
                        onDataPointSelection={handleOpenDrawer}
                        type={type}
                        {...getData()}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export const StackedChart100 = () => {
    return <StackedChart type="100%" />;
}
