import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';

import {
  selectAboutText,
  sendTextByCode
} from 'features/main/mainSlice';

export function AboutDialog({ children, component = "div", boxProps = {} }) {
  const [aboutOpen, setAboutOpen] = useState(false);
  const aboutText = useSelector(selectAboutText);
  const dispatch = useDispatch();


  const handlerClickAbout = () => {
    if (!aboutOpen) {
      dispatch(sendTextByCode("system_information_statistic"));
    }
    setAboutOpen(!aboutOpen);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={aboutOpen}
        onClose={handlerClickAbout}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"><div dangerouslySetInnerHTML={{ __html: aboutText.title }} /></DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"}>
            <div dangerouslySetInnerHTML={{ __html: aboutText.text }} />
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handlerClickAbout} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        onClick={handlerClickAbout}
        component={component}
        {...boxProps}
      >{children}</Box>
    </>
  );
}