import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { COLORS } from "constants";

import { fetchGetContent } from "./contentAPI";

const initialState = {
  list: {},
  warningContent: {},
  names: {},
  currentPage: "",
  type: "",
  selectedTab: false,
  warningOpen: false,
  pagination: {
    page: 0,
    pages: 0,
    limit: 100,
    rowsTotal: 0,
  },
  loading: {
    content: false,
  },
  config: {
    conflictsViewType: "stacked", // stacked | stacked100 | pie
  },
};

const formatByType = (filterType, value) => {
  switch (filterType) {

    case "SearchStringMulti":
      if (value && value.length) {
        return value.map(item => item.id);
      } else {
        return [];
      }

    case "SelectMulti":
      if (value && value.length) {
        return value.map(item => item.id);
      } else {
        return [];
      }

    case "SearchString":
      if (value && value.id) {
        return value.id;
      } else {
        return null;
      }

    case "DateTime":
      if (value) {
        return value;
      } else {
        return null;
      }

    default:
      break;
  }
}

export const sendGetContent = createAsyncThunk(
  "content/fetchSendGetContent",
  async ({ limit, page, tab: clickedTab, isWarnings, forceFilters = null, path } = {}, { getState, dispatch }) => {
    const { auth, pages, content } = getState();

    const { currentPage, list, sharedFilters } = pages;
    const { currentPage: currentPagePrev, pagination, selectedTab: selectedTabPrev } = content;

    let tabObj;
    let selectedTab;

    if (clickedTab === undefined) {
      if (selectedTabPrev === false) {
        selectedTab = list[currentPage].tabs.findIndex(tab => tab.selected) || 0;
      } else {
        if (isWarnings) {
          selectedTab = list[currentPage].tabs.findIndex(tab => tab.key === "table") || 0;
        } else {
          selectedTab = selectedTabPrev;
        }
      }
    } else {
      selectedTab = clickedTab;
    }

    tabObj = list[currentPage].tabs[selectedTab];
    const tab = tabObj.key;
    path = path || tabObj.data;

    if (!isWarnings) {
      dispatch(setSelectedTab(selectedTab));

      if (currentPage !== currentPagePrev) {
        dispatch(setCurrentPage(currentPage));
      }

      if (limit && limit !== pagination.limit) {
        dispatch(setLimit(limit));
        dispatch(setPage(0));
      }

      if (page !== undefined && page !== pagination.page) {
        dispatch(setPage(page));
      }
    }
    let sendFilters = {};
    let filters = list[currentPage].filters;

    Object.keys(filters).forEach(filterKey => {
      let value = null;
      if (sharedFilters.hasOwnProperty(filterKey)) {
        value = sharedFilters[filterKey].value;
      } else {
        value = filters[filterKey].value;
      }

      if (value) {
        sendFilters[filterKey] = formatByType(filters[filterKey].type, value);
      }
    });

    if (isWarnings) {
      sendFilters = {
        ...sendFilters,
        ...forceFilters
      }
    }

    const response = await fetchGetContent({
      key: auth.key,
      authkey: auth.authkey,
      path,
      tab,
      filters: sendFilters,
      limit: limit || pagination.limit,
      page: page !== undefined ? page : pagination.page,
    });

    return response;
  }
);



export const authSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setLimit: (state, action) => {
      state.pagination.limit = action.payload;
    },
    setWarningOpen: (state, action) => {
      state.warningOpen = action.payload;
    },
    setPage: (state, action) => {
      console.log(action.payload);
      state.pagination.page = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    updateConfig: (state, action) => {
      state.config = {
        ...state.config,
        ...action.payload
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendGetContent.pending, (state) => {
        state.status = "loading";
        if (!state.warningOpen) {
          state.loading.content = true;
        }
      })

      .addCase(sendGetContent.fulfilled, (state, action) => {
        state.status = "idle";
        if (!state.warningOpen) {
          state.loading.content = false;
        }

        if (state.selectedTab === false) {
          state.selectedTab = action.payload.firstTab || 0;
        }

        if (action.payload?.data) {
          let contentData = action.payload.data;

          if (!state.warningOpen) {
            // дополняем данные днями, данные о которых отсутствуют
            if (action.payload.type === "lineChart") {

              // const getDaysArray = (start, end, data) => {
              //   for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {

              //     // приводим все даты ко времени 00:00
              //     dt.setUTCHours(0, 0, 0, 0);
              //     const currDate = dt.getTime();

              //     var value = data.find(monthlyLog => monthlyLog[0] === currDate) || 0;
              //     if (value) {
              //       value = value[1];
              //     }
              //     arr.push([currDate, value]);
              //   }
              //   return arr;
              // }


              contentData = contentData.map(contentDataItem => {
                // find min max
                // let start = 9999999999999;
                // let end = 0;
                let additionalItems = [];

                console.log("contentDataItem", contentDataItem);

                contentDataItem.items = contentDataItem.items.map((item, i) => {
                  // start = Math.min(start, item.data[0][0]);
                  // end = Math.max(end, item.data[item.data.length - 1][0]);

                  // additionalItems.push({
                  //   ...item,
                  //   type: "column",
                  //   name: false,
                  //   color: COLORS[i]
                  // });

                  return {
                    ...item,
                    color: COLORS[i],
                    // type: "area",

                  }

                });


                let allItems = [].concat(contentDataItem.items, additionalItems);
                console.log(allItems);

                return {
                  ...contentDataItem,
                  items: allItems.map(itemsItem => {



                    return {
                      ...itemsItem,
                      // data: getDaysArray(start, end, itemsItem.data),
                    }
                  })
                };
              });

              console.log(contentData);
            } else if (action.payload.type === "pieChart") {
              contentData = {
                ...contentData,
                chart: contentData.chart.map(chartItem => ({
                  ...chartItem,
                  count: Object.values(chartItem.data).reduce((a, b) => a + b, 0)
                })),
              };

            }
          }

          if (state.warningOpen) {
            state.warningContent = contentData;
          } else {
            state.list[state.currentPage] = contentData;
            state.type = action.payload.type
          }

          state.names = action.payload.names;
          state.pagination.rowsTotal = contentData.rowsTotal;
          state.pagination.pages = Math.ceil(contentData.rowsTotal / state.pagination.limit);
        }
      })

      .addCase(sendGetContent.rejected, (state, action) => {
        state.list[state.currentPage] = {};
        state.status = "idle";
        state.loading.content = false;
      });
  },
});

export const {
  setCurrentPage,
  setSelectedTab,
  setLimit,
  setPage,
  setWarningOpen,
  updateFilters,
  clearSearchResult,
  updateSharedFilters,
  updateConfig,
} = authSlice.actions;

export const selectContent = (state) => state.content.list[state.content.currentPage] || {};
export const selectContentType = (state) => state.content.type || "";
export const selectWarningOpen = (state) => state.content.warningOpen;
export const selectNames = (state) => state.content.names || {};
export const selectWarningContent = (state) => state.content.warningContent || {};
export const selectLoading = (state) => state.content.loading;
export const selectPagination = (state) => state.content.pagination;
export const selectSelectedTab = (state) => state.content.selectedTab;
export const selectConfigAll = (state) => state.content.selectedTab;
export const selectConfigByKey = (state, key) => { return state.content.config[key] || null };

export default authSlice.reducer;
