export { API_URLS } from "./APIUrls";

export const APP_SERVICE = "43";

export const CONFIG = {
    title: "Статистика",
    subtitle: "Socmedica",
    formType: "full", // keyOnly | full // Полная форма входа/регистрации или только вход по ключу
    auth: {
        requiredKey: true, // При авторизации, помимо логина и пароля обязательно нужно ввести ключ
    },
    registration: {
        twoColumns: true, // Отображать поля ввода в две колонки
        fields: [
            // email, // required
            // firstName,  // required
            "middleName",
            // lastName, // required
            "lpuName",
            "department",
            // password, // required
            // password2, // required
        ],
    },
};

export const API_KEYS = {
    service: "7e649ecb733d2af1",
    passReset: "9152ea8756f8f2b3",
    passRequest: "c47ee7e666d6be7e",
    checkReg: "340f725c9ccb9779",
};

export const AUTH_PATHS = [
    {
        title: "Вход в систему",
        type: "login",
        path: "/login",
    },
    {
        title: "Восстановить пароль",
        type: "login",
        path: "/resetpass",
    },
    {
        title: "Задать новый пароль",
        type: "login",
        path: "/newpass",
    },
    {
        title: "Регистрация",
        type: "registration",
        path: "/registration",
    },
    {
        title: "Подтверждение регистрации",
        type: "registration",
        path: "/confirm",
    },
    {
        title: "Запрос кода регистрации",
        type: "registration",
        path: "/getconfirm",
    },
];

