import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

import {  Stack } from '@mui/material';

import { checkErrors } from 'helpers';

import { Button } from "../Button";
import { TextField } from "../TextField";

import {
  selectAuthData,
  selectLoading,
  selectErrors,
  setErrors,
  updateAuthData,
  resetLoginResult,
  sendLoginWhithKey,
} from '../../authSlice';


export function LoginByKey() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const authData = useSelector(selectAuthData);
  const errors = useSelector(selectErrors);

  const handleSubmit = async () => {
    const formErrors = checkErrors(authData, [
      { name: "key", required: true },
    ]);

    dispatch(setErrors(formErrors));

    if (!Object.keys(formErrors).length) {
      dispatch(resetLoginResult());
      dispatch(sendLoginWhithKey());
    }
  };

  const handleChangeField = (event) => {
    const name = event.target.id;
    let value = event.target.value;

    dispatch(updateAuthData({
      [name]: value.trim(),
    }));
  };

  useEffect(() => {
    dispatch(setErrors({}));
  }, [dispatch]);

  return (
    <Stack
      direction="column"
      spacing={2}
    >
      <TextField
        error={!!errors.key}
        id="key"
        label="Ключ"
        helperText={errors.key}
        value={authData.key}
        onChange={handleChangeField}
      />

      <Button
        onClick={handleSubmit}
        loading={loading}
        title="Отправить"
      />
    </Stack>
  );
}
