import { API_URLS, APP_SERVICE } from "constants";
import { POST } from "helpers";

export function fetchSearch({ key, authkey, text, advancedOptions }) {
  let options = {};

  advancedOptions.forEach(option => {
    options[option.key] = option.value;
  });

  return POST({
    path: API_URLS.main.Search,
    data: {
      text,
      key,
      authkey,
      service: APP_SERVICE,
      ...options,
    },
  });
}

export function fetchGetPages({ key, authkey }) {
  return POST({
    path: API_URLS.main.GetPages,
    data: {
      key,
      authkey,
      service: APP_SERVICE,
    },
  });
}
