import React, { memo } from "react";
import { isObject } from "helpers";

import { useSelector } from 'react-redux';
import { DRAWER_WIDTH, TABLE_ROW_STATUSES } from "constants";

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Paper,
    TableRow,
    TablePagination,
} from "@mui/material";

import { selectDrawerOpen } from "features/main/mainSlice";
import { TableCell } from "./components/TableCell";

export const DataGridComponent = (props) => {
    const {
        rows,
        columns,
        pageSize,
        page,
        rowsTotal,
        onRowsPerPageChange,
        onPageChange,
        onRowClick,
        hasDetails,
        fullSize,
    } = props;

    const open = useSelector(selectDrawerOpen);
    const drawerWidth = open ? (DRAWER_WIDTH + 20) : 0;

    const getStatus = (status) => {
        if (!TABLE_ROW_STATUSES.hasOwnProperty(status)) {
            status = "Default";
        }

        return TABLE_ROW_STATUSES[status];
    }

    return <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{
            maxHeight: "calc(100vh - 195px)",
            maxWidth: fullSize ? "100%" : `calc(100vw - ${drawerWidth}px)`
        }}>
            <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                size="small"
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{ width: "6px", padding: "0 !important" }}
                            key={0}
                            empty
                        >
                        </TableCell>
                        {columns.map((column) => <TableCell
                            header
                            key={column.field}
                        >{column.headerName}</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow
                            key={i}
                            onClick={hasDetails ? onRowClick : null}
                            sx={{ cursor: hasDetails ? "pointer" : "default" }}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell
                                sx={{ backgroundColor: getStatus(row.status).color, padding: "3px !important" }}
                                title={getStatus(row.status).title}
                                key={0}
                                empty
                            >
                            </TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.field}
                                    noMinWidth={column.field === "warnings"}
                                    cellDataAdditional={column.field === "drug" && row["drugsGrouping"] ? row["drugsGrouping"] : null}
                                >
                                    {isObject(row[column.field]) ? row[column.field] : row[column.field]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 50, 100, 200, 500]}
            component="div"
            count={rowsTotal}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{ boxShadow: "0 -1px 0 #e0e0e0" }}
        /></Paper>;
}
export const DataGrid = memo(DataGridComponent);
