import { SERVER } from './server';

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9046",
        auth: "https://cs.socmedica.com",
    },
    dev: {
        api: "https://service.socmedica.dev:9046",
        auth: "https://csdev.socmedica.com",
    },
    box: {
        api: "http://ecp.box:30946",
        auth: "http://ecp.box:44380",
    },
};

const getUrls = () => {
    const { api: APIServer, auth: authServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        auth: {
            registration: authServer + "/api/registration/send",
            confirm: authServer + "/api/registration/confirmemail",
            getConfirm: authServer + "/api/registration/getconfirmemail",
            login: authServer + "/api/user/authorization",
            recovery: authServer + "/api/user/recovery",
            resetPass: authServer + "/api/user/resetPassword",

            checkAuth: APIServer + "/Auth/CheckAuth",
        },
    }
}

export const API_URLS = getUrls();
