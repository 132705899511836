import { COLORS } from "constants";

const options = {
  colors: COLORS,
  chart: {
    width: 780,
    offsetX: -120,
    type: 'pie',
    toolbar: {
      show: true,
      offsetX: -260,
      tools: {
        download: true,
      },
    },
  },
  fill: {
    type: 'gradient',
  },
  legend: {
    height: 160,
    offsetX: 120,
    position: 'bottom',
    horizontalAlign: 'left',
    formatter: function (seriesName, opts) {
      return [seriesName, " - ", `${opts.w.globals.series[opts.seriesIndex]}<br/>`,]
    },
    labels: {
      // useSeriesColors: true
    },
    markers: {
      offsetX: -6,
      offsetY: 1
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -10,
      },
      customScale: 1,

      donut: {
        size: '60%',
        background: 'transparent',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
            formatter: function (val) {
              return val
            }
          },
          value: {
            show: true,
            fontSize: '22px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            color: undefined,
            offsetY: 16,
            formatter: function (val) {
              return val
            }
          },
          total: {
            show: true,
            showAlways: false,
            label: "Всего:",
            fontSize: '13px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 600,
            color: '#373d3f',
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b
              }, 0)
            }
          }
        }
      }
    }
  },
  responsive: [
    // {
    //   breakpoint: 1800,
    //   options: {
    //     chart: {
    //       width: 580
    //     },
    //   }
    // },
    {
      breakpoint: 1400,
      options: {
        chart: {
          width: 580
        },
      }
    },

  ]
};

export default options;