import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, Autocomplete } from '@mui/material';

import {
    sendSearch,
    selectLoading,
} from "features/pages/pagesSlice";

/**
 * @todo Отрефакторить когда придут настоящие данные. Никаких запросов тут не должно быть
 * @param {*} param0 
 * @returns 
 */
function SelectMultiComponent({ title, value, name, onChange, listValue }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const { search: APILoading } = useSelector(selectLoading);
    const loading = open && APILoading;

    const handleChange = (_, value) => {
        onChange && onChange({ name, value });
    }

    const handleInputChange = (event) => {
        if (!event) {
            return;
        }
        const text = event.target.value || "";

        setInputValue(text);
    }

    useEffect(() => {
        if (open === false && inputValue) {
            setInputValue("");
        }
    }, [dispatch, open, inputValue]);

    useEffect(() => {
        if (inputValue && inputValue.length > 1) {
            dispatch(sendSearch({ text: inputValue }));
        }
    }, [dispatch, inputValue]);

    return (
        <Autocomplete
            size='small'
            multiple={true}
            id={`select-multi-${name}`}
            open={open}
            value={value || []}
            disabled={!listValue.length}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={listValue}
            loading={loading}
            clearText="Очистить"
            loadingText="загрузка..."
            noOptionsText="нет результатов"
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={title}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            )}
            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
        />
    );
}

export const SelectMulti = memo(SelectMultiComponent);
