import React from 'react';

import { AUTH_PATHS } from "../../constants";

import { NavLink, Link } from "react-router-dom";
import {
  Divider,
  Stack,
} from '@mui/material';

import { AboutDialog } from "UI/AboutDialog";

import styles from './Navigation.module.css';


export function Navigation({ tab }) {
  const isRegistration = tab === 1;

  let paths = [];
  if (isRegistration) {
    paths = AUTH_PATHS.filter(item => item.type === "registration");
  } else {
    paths = AUTH_PATHS.filter(item => item.type === "login");
  }


  return (
    <Stack direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
      className={styles.links}
    >
      {paths.map(item => <NavLink
        key={item.path}
        className={({ isActive }) => isActive ? styles.linkActive : undefined}
        to={item.path}
      >{item.title}</NavLink>
      )}
      <AboutDialog>
        <Link href="#action=about" >О программе</Link>
      </AboutDialog>
    </Stack>
  );
}