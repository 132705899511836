import React from "react";

import { List, ListItemText } from "@mui/material";

export const DrugsList = ({ cellData, cellDataAdditional }) => {
    const colors = ["#F3F4F5", "#FBFDEF", "#F6E7FF", "#E1F7F7", "#FBFDEF", "#FFEDDE"];
    const borderColors = ["#DCDDDE", "#FFE21F", "#DC73FF", "#6DFFFF", "#D9E778", "#FF851B"];
    let groupedObj = {};
    let grouped = [];
    let groupless = [];

    if (!Array.isArray(cellData)) {
        return "";
    }

    cellData.forEach((row, i) => {
        if (cellDataAdditional && cellDataAdditional[i]) {
            let groupId = cellDataAdditional[i];

            if (!groupedObj.hasOwnProperty(groupId)) {
                groupedObj[groupId] = [];
            }
            groupedObj[groupId].push(row);

        } else {
            groupless.push(row);
        }
    });

    grouped = Object.values(groupedObj);

    return <List disablePadding sx={{ ml: 2, mr: 2 }}>
        <>
            {grouped.map((groupArr, i) => <ListItemText
                key={i}
                title={"Раствор"}
                sx={{
                    display: "inline-block",
                    padding: "2px 6px 2px 2px",
                    outline: `1px solid ${borderColors[i]}`,
                    backgroundColor: colors[i],
                }}
            >{groupArr.map((row, i2) => <ListItemText
                secondary={row}
                secondaryTypographyProps={{ component: "div" }}
                key={i2}
                sx={{
                    paddingLeft: "5px",
                }}
            />)}
            </ListItemText>)}

            {groupless.map((row, i) => <ListItemText
                secondary={row}
                key={i}
                sx={{
                    paddingLeft: "5px",
                }}
            />)}
        </>
    </List>;
}
