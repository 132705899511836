import React, { useState, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isObject, getGroupColor } from "helpers";
import { WARNINGS_ICONS } from "constants";

import { Alert, Dialog, Box, Tooltip } from '@mui/material'; // Drawer
// import { ruRU } from '@mui/x-data-grid';

import {
    selectContent,
    selectPagination,
    sendGetContent,
    selectLoading,
    selectNames,
    selectWarningContent,
} from "features/content/contentSlice";

// import { DataGrid } from "UI/DataGrid/DataGrid_VIRT";

// import { data as testData, pagination as testPagination } from "./dataMock";

import { Detailed } from "features/content";
import { DataGrid } from "UI";

const Icon = ({ children, tooltip = "", value = 0 }) => {
    const color = getGroupColor(value);

    return <Tooltip title={tooltip}>
        <Box sx={{
            width: 27,
            height: 27,
            borderRadius: "50%",
            border: `3px solid ${color}`,
            overflow: "hidden",
            opacity: 0.8,
            flexShrink: 0,
            "&:hover": {
                opacity: 1
            }
        }}>{children}</Box>
    </Tooltip>
}

export const TableComponent = ({ isWarningTable = false, fullSize }) => {
    const dispatch = useDispatch();
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const warningContent = useSelector(selectWarningContent);
    const names = useSelector(selectNames);
    const pagination = useSelector(selectPagination);
    const { content: loading } = useSelector(selectLoading);
    let content = useSelector(selectContent);

    if (isWarningTable) {
        content = { ...warningContent };
    }

    const rows = useMemo(() => content.rows?.map(row => {
        const parseIcons = (data) => {

            if (!isObject(data)) {
                return "";
            }

            let ids = [];
            let values = [];
            let icons = [];
            let tooltips = [];

            for (let key in data) {
                ids.push(key);
                values.push(data[key]);

                if (WARNINGS_ICONS[key] === undefined) {
                    icons.push("");
                    tooltips.push(key);
                } else {
                    const style = WARNINGS_ICONS[key] === "noage" ? { width: "37px", margin: "-7px 0 0 -6px" } : { width: "17px", margin: "2px 0 0 2px" };
                    icons.push(<img src={`/icons/new/${WARNINGS_ICONS[key]}.svg`} style={style} alt="" />);

                    let name = names.warnings && names.warnings[key] ? names.warnings[key] : "";
                    tooltips.push(name);
                }
            }

            return <Box sx={{
                display: "flex",
                flexWrap: "nowrap",
                gap: 1,
            }}>{icons.map((icon, i) => <Icon key={i} tooltip={tooltips[i]} value={values[i]}>{icon}</Icon>)}</Box>;
        }

        return {
            ...row,
            warnings: parseIcons(row.warnings),
        };
    })
        || [], [content.rows, names?.warnings]);

    let columns = useMemo(() => {
        let memoColumns = content.columns || [];
        memoColumns = memoColumns.map(column => {
            const { width, ...columnNeeded } = column;

            return {
                ...columnNeeded,
                flex: 1,
                minWidth: column.widthPX,
            }
        });
        memoColumns = memoColumns.filter(column => column.visible);

        return memoColumns;

    }, [content.columns]);

    const hasDetails = !!rows.find(rows => rows.detailed);

    const handleChangeRowsPerPage = useCallback((event) => dispatch(sendGetContent({ limit: +event.target.value })), [dispatch]);
    const handleChangePage = useCallback((_, page) => dispatch(sendGetContent({ page })), [dispatch]);

    const handleClickRow = useCallback((e) => {
        let td = null;

        if (e.target.nodeName === "TD") {
            td = e.target;
        } else {
            td = e.target.offsetParent;
        }

        const rowIndex = td?.parentNode?.rowIndex || null;

        if (rowIndex) {
            setDrawerOpen(true);
            setSelectedRowIndex(rowIndex - 1);
        }
    }, []);

    const handleCloseDrawer = useCallback((e) => setDrawerOpen(false), []);

    if (!rows.length && !loading) {
        if (isWarningTable) {
            return <Alert severity="info">Загрузка данных...</Alert>;
        }
        return <Alert severity="info">Нет данных. Попробуйте изменить фильтры.</Alert>;
    }

    return <>
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={drawerOpen}
            onClose={handleCloseDrawer}
        >
            <Detailed isWarningTable={isWarningTable} rowIndex={selectedRowIndex} onClose={handleCloseDrawer} />
        </Dialog>
        <DataGrid
            hasDetails={hasDetails}
            rows={rows}
            page={pagination.page}
            columns={columns}
            pageSize={pagination.limit}
            rowsTotal={pagination.rowsTotal}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            onRowClick={handleClickRow}
            fullSize={fullSize}
        />
    </>;
}

export const Table = memo(TableComponent);
