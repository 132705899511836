import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, Box, Autocomplete, CircularProgress } from '@mui/material';

import {
    getSearchType,
    sendSearch,
    clearSearchResult,
} from "features/pages/pagesSlice";

function SearchStringMultiComponent({ title, name, onChange, value: filterValue, advancedOptions }) {
    const dispatch = useDispatch();

    const searchType = getSearchType(advancedOptions);

    const [value, setValue] = React.useState(filterValue || []);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const options = useSelector(state => state.pages.searchResult[searchType]);
    const loading = useSelector(state => state.pages.searchLoading[searchType]);
    // const loading = open && APILoading;

    const handleChange = (_, newValue) => {
        setValue(newValue);
        onChange && onChange({ name, value: newValue });
    }

    const handleInputChange = (_, newInputValue) => {
        const isChanged = inputValue !== newInputValue;

        setInputValue(newInputValue);

        if (newInputValue && newInputValue.length > 1 && isChanged) {
            dispatch(sendSearch({ text: newInputValue, advancedOptions }));
        }
    }

    const handleClose = () => {
        setOpen(false);
        dispatch(clearSearchResult(searchType));

        if (inputValue) {
            setInputValue("");
        }
    }

    return (
        <Autocomplete
            size='small'
            multiple={true}
            id={`search-string-multi-${name}`}
            value={value || []}
            open={open}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onOpen={() => setOpen(true)}
            onClose={handleClose}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={options || []}
            loading={loading}
            clearText="Очистить"
            loadingText="загрузка..."
            noOptionsText="нет результатов"
            onReset={handleClose}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={title}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {open && loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}

            renderOption={(props, option) => <li {...props} key={option.id}><Box
                sx={{
                    flexGrow: 1,
                    mt: -1,
                    mr: -2,
                    mb: -1,
                    ml: -2,
                    '& span': {
                        display: "flex",
                        fontSize: "10px",
                        color: '#586069',
                    },
                }}
            >
                {option.name}
                <span>{option.level}</span>
            </Box></li>}
        />
    );
}
export const SearchStringMulti = memo(SearchStringMultiComponent);
