import { COLORS } from "constants";

const options = {
    colors: COLORS,
    chart: {
        type: 'bar',
        height: 380,
        toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
    },
    plotOptions: {
        bar: {
            columnWidth: "80%",
            distributed: true,
            horizontal: false,
        }
    },
    dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
            colors: ['#fff']
        },
        formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
            enabled: true
        }
    },
    xaxis: {        
        labels: {
            show: false
        }
    },
    tooltip: {
        y: {
            title: {
                formatter: function () {
                    return ''
                }
            }
        }
    }
};

export default options;