import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import { selectContent, selectLoading } from "features/content/contentSlice";
import { Divider, Alert } from "@mui/material";

import { GroupSelector } from "features/content";

// import content from "./dataMock";
import options from "./options";

const ChartComponent = ({ name, data, title }) => {
    if (data[0] && data[0].values) {
        data = data.map(item => {
            return {
                name: item.name,
                data: item.values
            }
        })
    }

    return <Chart options={{
        ...options,
        yaxis: {
            ...options.yaxis,
            title: {
                text: name || "",
            },
        },
        title: {
            ...options.yaxis,
            text: title || "",
        },
    }}
        series={data}
        type="area"
        height={380}
    />;
}

export const LineChart = () => {
    const content = useSelector(selectContent);
    const [group, setGroup] = React.useState(0);
    const { content: loading } = useSelector(selectLoading);

    if ((!content.length && !loading) || !Array.isArray(content)) {
        return <Alert severity="info">Нет данных. Попробуйте изменить фильтры.</Alert>;
    }

    let chartData = content[group]?.items || [];
    let chartName = content[group]?.name || "";
    let groups = content.map(group => group.name);

    const handleChangeGroup = (event) => {
        setGroup(event.target.value);
    }

    return (
        <>
            {groups.length > 1 &&
                <GroupSelector
                    onChange={handleChangeGroup}
                    value={group}
                    items={[...groups, "Показать все"]}
                />
            }

            {group === groups.length ? groups.map((_, i) =>
                <Fragment key={`group_${i}`}>
                    <ChartComponent
                        name={content[i]?.name || ""}
                        data={content[i]?.items || []}
                    />
                    <Divider sx={{ mb: 5 }} />
                </Fragment>) : null}

            {group !== groups.length &&
                <ChartComponent
                    data={chartData}
                    title={groups.length === 1 ? chartName : ""}
                />}
        </>
    );
}
