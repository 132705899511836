import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs as MUITabs, Tab, Box, Alert } from '@mui/material';

import {
    Content,
} from "features/content";

import {
    selectTabs,
} from "features/pages/pagesSlice";

import {
    selectSelectedTab,
    sendGetContent,
    selectLoading,
} from "features/content/contentSlice";

import { Loader } from 'UI';


function TabPanel(props) {
    const { value, index } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={{ overflowY: "auto", overflowX: "hidden" }}
        >
            {value === index && (
                <Box sx={{ p: 1, pt: 3 }}>
                    <Content />
                </Box>
            )}
        </Box>
    );
}

export const Tabs = () => {
    const dispatch = useDispatch();
    const tabs = useSelector(selectTabs);
    const selectedTab = useSelector(selectSelectedTab);
    const { content: loading } = useSelector(selectLoading);

    const handleChange = (_, newValue) => {
        dispatch(sendGetContent({ tab: newValue, page: 0 }));
    };

    return (<>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <MUITabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                {tabs.map((tab, i) => <Tab
                    label={tab.name}
                    key={tab.key}
                    id={`tab-${i}`}
                    aria-controls={`tabpanel-${i}`} />)}
            </MUITabs>
        </Box>
        {
            selectedTab === false ? <Loader loading={loading} ><Alert severity="info" sx={{ m: 2 }}>Укажите фильтры в левой колонке и нажмите "Сформировать отчёт".</Alert></Loader>
                :
                tabs.map((tab, i) => <TabPanel value={selectedTab} key={tab.key} index={i} />)
        }
    </>);
}
