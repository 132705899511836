import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Grid, List, ListItem, ListItemText, Box, Container, IconButton, Link } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { DrugsList } from "features/content/";

import { selectContent, selectWarningContent } from "features/content/contentSlice";
import { selectAuthData } from "features/auth/authSlice";

const DetailedComponent = ({ rowIndex, onClose, isWarningTable = false }) => {
    const authData = useSelector(selectAuthData);
    let content = useSelector(selectContent);
    const warningContent = useSelector(selectWarningContent);

    if (isWarningTable) {
        content = { ...warningContent };
    }

    const row = content.rows[rowIndex];

    let data = { ...row.detailed };

    if (row.link) {
        // console.log(content.url.split(""))
        data["Электронный Клинический Фармаколог"] = <Box component="span" sx={{ display: "flex", gap: "6px" }}><img width="16" height="16" alt="ECP" src="/img/ecp_logo.png" /><Link target={"_blank"} href={`${content.url || ""}statistics/${authData.key}/${authData.authkey||"authkey"}/${row.link}`}>Перейти в ЭКФ</Link></Box>;
    }

    const dataKeys = Object.keys(data);
    const dataCount = dataKeys.length;

    dataKeys.forEach(key => {
        if (["Препараты", "Tорговое наименование"].includes(key) && row["drugsGrouping"]) {
            data[key] = <DrugsList cellData={row["drug"]} cellDataAdditional={row["drugsGrouping"]} />;
        }
    })

    const valueParse = (key) => {
        if (!data[key]) {
            return "";
        }

        let value = Array.isArray(data[key]) ? data[key] : [data[key]];

        return <List dense={true} component={"div"}>
            {value.map((v, i) =>
                <ListItem key={i} disablePadding sx={{ ml: 2 }} >
                    <ListItemText
                        secondaryTypographyProps={{ component: "div" }}
                        secondary={v}
                    />
                </ListItem>
            )}
        </List>;
    }

    return <Grid container spacing={2} padding={5}>
        <Container
            disableGutters={true}
            maxWidth={"98%"}
            sx={{
                display: "flex",
                justifyContent: "end",
                position: "absolute",
                width: "98%"
            }}>
            <IconButton onClick={onClose}>
                <CloseIcon fontSize="inherit" />
            </IconButton>
        </Container>
        {[...Array(dataCount)].map((_, i) => {
            return <Grid xs={12} sm={6} md={4} item key={i}>
                <Box sx={{ fontWeight: 600 }}>{dataKeys[i]}: {valueParse(dataKeys[i])}</Box>
            </Grid>;
        })}
    </Grid>;
}

export const Detailed = memo(DetailedComponent);
