import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import { Divider, Alert } from "@mui/material";

import { GroupSelector } from "features/content";
import { selectContent, selectLoading } from "features/content/contentSlice";

// import content from "./dataMock";
import options from "./options";

const ChartComponent = ({ name, data, title, categories }) => {
    return <Chart options={{
        ...options,
        yaxis: {
            ...options.yaxis,
            title: {
                text: name || "",
            },
        },
        xaxis: {
            categories: categories,
            labels: {
                show: false
            }
        },
        title: {
            ...options.yaxis,
            text: title || "",
        },
    }}
        type='bar'
        series={data}
        height={350}
    />;
}

const getData = (content, group) => {
    let name = "";
    let data = [];
    let categories = [];

    data = content[group]?.data ? content[group]?.data.map(v => v[1]) : [];
    categories = content[group]?.data ? content[group]?.data.map(v => v[0]) : [];
    name = content[group]?.name || "";

    return {
        name,
        data: [{
            name,
            data
        }],
        categories
    };
}

// Пропранолол
// Варфарин натрия

export const BarChart = () => {
    const [group, setGroup] = React.useState(0);
    const content = useSelector(selectContent);
    const { content: loading } = useSelector(selectLoading);

    if ((!content.length && !loading) || !Array.isArray(content)) {
        return <Alert severity="info">Нет данных. Попробуйте изменить фильтры.</Alert>;
    }

    let groups = content.map(group => group.name);

    const handleChangeGroup = (event) => {
        setGroup(event.target.value);
    }

    return (
        <>
            {groups.length > 1 && <GroupSelector items={[...groups, "Показать все"]} value={group} onChange={handleChangeGroup} />}

            {group === groups.length ? groups.map((_, i) =>
                <>
                    <ChartComponent
                        key={`group_${i}`}
                        {...getData(content, i)}
                    />
                    <Divider />
                </>) : null}

            {group !== groups.length && <ChartComponent
                {...getData(content, group)}
            />}
        </>
    );
}
