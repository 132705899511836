import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-apexcharts";

import { getGroupColor } from "helpers";

import {
    sendGetContent,
    selectContent,
    selectLoading,
    selectWarningOpen,
    setWarningOpen,
} from "features/content/contentSlice";
import { Table } from "features/content";

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Alert, Dialog, Slide } from '@mui/material';

// import data from "./dataMock";
import options from "./options";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChartComponent = ({ name, selectedPiece, data, labels, onDataPointSelection, fillType, colors }) => {
    let fill = fillType === 1 ? {} : {
        type: 'pattern',
        pattern: {
            style: 'circles',
            width: 4,
            height: 4,
            strokeWidth: 3
        }
    };

    return <Chart options={{
        ...options,
        ...(fillType === 1 ? { colors } : {}),
        labels,
        title: {
            text: name,
            index: selectedPiece, // Параметр нужен только для того, чтобы при клике на правый пирог данные отличались (в случае когда при клике на левый пирог получаем одинаковые данные), иначе кеш не обновит параметры. По сути @shitfix
            align: 'center'
        },
        fill: {
            ...options.fill,
            ...fill,
        },
        chart: {
            ...options.chart,
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    onDataPointSelection(config.dataPointIndex);
                },
                legendClick: function (chartContext, seriesIndex, config) {
                    onDataPointSelection(seriesIndex);
                }
            },
        },
    }}
        type='donut'
        series={data}
        width={780}
    />;
}

export const PieChart = () => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector(selectWarningOpen);
    const data = useSelector(selectContent);
    const [selectedPiece, setSelectedPiece] = useState(null);
    const { content: loading } = useSelector(selectLoading);

    let chart = useMemo(() => [...data.chart || []], [data.chart]);
    let content = {
        ...data,
        chart: chart.sort((a, b) => b.count - a.count),
    };

    const conflictTypes = useMemo(() => {
        const types = Array.isArray(content.conflictTypes) ? content.conflictTypes : [];
        return types.map(type => ({
            ...type,
            color: getGroupColor(type.value)
        }));
    }, [content.conflictTypes]);

    const getData = () => {
        const data = chart?.map(v => Object.values(v.data).reduce((a, b) => a + b, 0)) || [];
        const labels = chart?.map(v => v.name) || [];

        return {
            data,
            labels
        };
    }

    const getData2 = (index) => {
        const data = Object.values(chart?.[index]?.data || {});
        let colors = [];
        const labels = Object.keys(chart?.[index]?.data || {}).map(v => {
            const typeObj = conflictTypes.find(v2 => +v2.id === +v) || {};
            colors.push(typeObj.color);
            return typeObj.name || "--без названия--";
        });

        return {
            data,
            colors,
            labels
        };
    }

    const content_1 = getData();
    const content_2 = getData2(selectedPiece);

    const handlePointSelection = useCallback(index => setSelectedPiece(index), []);

    const handleOpenDrawer = useCallback((point) => {
        const ids = Object.keys(chart?.[selectedPiece]?.data || []);
        const id = chart?.[selectedPiece]?.id || null;
        const conflict = conflictTypes.find(conflictType => conflictType.id === +ids[+point]);

        dispatch(setWarningOpen(true));
        dispatch(sendGetContent({
            isWarnings: true,
            forceFilters: {
                warningTexts: [+id],
                valueA: +conflict.value || null
            }
        }));
    }, [chart, conflictTypes, dispatch, selectedPiece]);

    const handleCloseDrawer = useCallback((e) => {
        dispatch(setWarningOpen(false));
    }, [dispatch]);

    if ((!content.chart.length && !loading) || !Array.isArray(content.chart)) {
        return <Alert severity="info">Нет данных. Попробуйте изменить фильтры.</Alert>;
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"xl"}
                TransitionComponent={Transition}
                open={drawerOpen}
                onClose={handleCloseDrawer}
            >
                <Table isWarningTable fullSize />
            </Dialog>
            <Grid container>
                <Grid
                    key={`group_1`}                    
                    md={6}
                >
                    <ChartComponent
                        {...content_1}
                        onDataPointSelection={handlePointSelection}
                        fillType={0}
                        name="Тип взаимодействия"
                    />
                </Grid>
                <Grid
                    key={`group_2`}
                    md={6}
                >
                    {selectedPiece !== null ? <ChartComponent
                        {...content_2}
                        name={"Уровень конфликта"}
                        selectedPiece={selectedPiece}
                        onDataPointSelection={handleOpenDrawer}
                        fillType={1}
                    /> : null}
                </Grid>
            </Grid>
        </div>
    );
}
