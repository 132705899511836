import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Stack } from '@mui/material';

import { checkErrors } from 'helpers';

import { Button } from "../Button";
import { TextField } from "../TextField";

import {
  selectAuthData,
  selectLoading,
  selectErrors,
  setErrors,
  updateAuthData,
  sendResetPass,
} from '../../authSlice';


export function NewPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const authData = useSelector(selectAuthData);
  const errors = useSelector(selectErrors);
  const { code: urlCode, email: urlEmail } = useParams();

  const handleSubmit = async () => {
    const formErrors = checkErrors(authData, [
      { name: "email", type: "email", required: true },
      { name: "recoveryCode", required: true },
      { name: "password", type: "password", required: true },
    ]);
    dispatch(setErrors(formErrors));

    if (!Object.keys(formErrors).length) {
      const request = await dispatch(sendResetPass());

      if (request.meta.requestStatus !== "rejected") {
        navigate("/login");
      }
    }
  };

  const handleChangeField = (event) => {
    const name = event.target.id;
    let value = event.target.value;

    dispatch(updateAuthData({
      [name]: value.trim(),
    }));
  };

  useEffect(() => {
    dispatch(setErrors({}));
  }, [dispatch]);

  useEffect(() => {
    if (urlCode) {
      dispatch(updateAuthData({
        recoveryCode: urlCode.trim(),
      }));
    }

    if (urlEmail) {
      dispatch(updateAuthData({
        email: urlEmail.trim(),
      }));
    }
  }, [dispatch, urlCode, urlEmail]);

  return (
    <Stack
      direction="column"
      spacing={2}
    >
      <TextField
        error={!!errors.email}
        id="email"
        label="Email"
        helperText={errors.email}
        value={authData.email}
        onChange={handleChangeField}
      />
      <TextField
        error={!!errors.recoveryCode}
        id="recoveryCode"
        label="Код подтверждения"
        helperText={errors.recoveryCode}
        value={authData.recoveryCode}
        onChange={handleChangeField}
      />
      <TextField
        error={!!errors.password}
        id="password"
        label="Новый пароль"
        helperText={errors.password}
        value={authData.password}
        onChange={handleChangeField}
      />

      <Button
        onClick={handleSubmit}
        loading={loading}
        title="Изменить пароль"
      />
    </Stack>
  );
}
